import { createSlice, createSelector } from '@reduxjs/toolkit'
import { api } from '@/api'
import { AppState } from '@/state'

export interface IChainState {
  supportedChains: any
}

const initialState: IChainState = {
  supportedChains: []
}

export const chainsSlice = createSlice({
  name: 'chains-slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      // @ts-ignore
      api.endpoints.getChains.matchFulfilled,
      (state, { payload }) => {
        state.supportedChains = payload?.data || []
      }
    )
  }
})

const selectSelf = (state: AppState) => state

export const supportedChainsSelector = createSelector(selectSelf, (state) => {
  const verifiedCrypto = state.cryptocurrencies?.verifiedCryptoCurrencies || []
  const supportedChains = state?.supportedChains.supportedChains || []

  const detailedSupportedChains = []
  // missing BSC as verified tokens
  for (const chain of supportedChains) {
    for (const tokens of verifiedCrypto) {
      for (const token of tokens.addresses) {
        if (token.type === 'Coin' && chain.id === token.blockchainId) {
          detailedSupportedChains.push({
            ...chain,
            token,
            symbol: tokens.symbol
          })
        }
      }
    }
  }

  return detailedSupportedChains
})
