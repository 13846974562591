import { FC } from 'react'
import { IPlan, useLazyGetSubscriptionQuery } from '@/api/subscription-api'
import { useMediaQuery } from '@mantine/hooks' // Hook to handle media queries
import SideNavBar from './SideNavBar'
import { Drawer } from '@mantine/core'
import { TXN_COLUMNS_STORAGE_KEY, TXN_FILTERS_STORAGE_KEY } from '@/views/Transactions/interface'
import { resetWallet, setFakeLoader } from '@/slice/wallets/wallet-slice'
import { useAppSelector, useAppDispatch } from '@/state'
import { setIsMobileDrawerOpen } from '@/slice/platform/platform-slice'
import { selectIsMobileDrawerOpen } from '@/slice/platform/platform.selectors'
import MobileMenu from './components/MobileMenu'
import { useRouter } from 'next/router'
import useAuth from '@/hooks/useAuth'

export interface ISideBarProps {
  orgList: any
  currentOrg: any
  bannerDisplayed?: boolean
  user: {
    role: string
    firstName: string
    lastName: string
    loginCredentials: string
  }
  plan?: IPlan
}

const SidebarContainer: FC<ISideBarProps> = ({ orgList, currentOrg, bannerDisplayed, user, plan }) => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { logoutRedirect } = useAuth()
  const isMobileDrawerOpen = useAppSelector(selectIsMobileDrawerOpen)
  const currentPage = router.route

  const [triggerGetSubscription] = useLazyGetSubscriptionQuery()

  const onCloseDrawer = () => {
    dispatch(setIsMobileDrawerOpen(false))
  }

  const onClickCreateOrg = () => {
    console.log('create org')
  }
  const onClickSelectOrg = (_value) => {
    dispatch(resetWallet())
    dispatch(setFakeLoader(true))
    window.sessionStorage.removeItem(TXN_FILTERS_STORAGE_KEY)
    window.sessionStorage.removeItem(TXN_COLUMNS_STORAGE_KEY)

    triggerGetSubscription({ organizationId: _value })
    router.push(`/${_value}/dashboard`)
  }
  const onClickLogout = () => {
    logoutRedirect()
  }

  if (isMobile === undefined) {
    return null
  }

  if (isMobile) {
    return (
      <Drawer
        styles={{ body: { padding: 0, height: '100%' } }}
        withCloseButton={false}
        size="xs"
        opened={isMobileDrawerOpen}
        onClose={onCloseDrawer}
      >
        <MobileMenu
          orgList={orgList}
          currentOrg={currentOrg}
          user={user}
          plan={plan}
          currentPage={currentPage}
          onClickLogout={onClickLogout}
          onClickCreateOrg={onClickCreateOrg}
          onClickSelectOrg={onClickSelectOrg}
        />
      </Drawer>
    )
  }
  return (
    <SideNavBar orgList={orgList} currentOrg={currentOrg} bannerDisplayed={bannerDisplayed} user={user} plan={plan} />
  )
}

export default SidebarContainer
