import { createSlice } from '@reduxjs/toolkit'
import { featureFlagApi } from '@/api/feature-flag'

interface FeatureFlag {
  env?: string[]
  users?: string[]
  organizations?: string[]
  isEnabled?: boolean
}

enum ENVIRONMENT {
  localhost = 'localhost',
  development = 'development',
  staging = 'staging',
  production = 'production',
  demo = 'demo'
}

interface FeatureFlagState {
  [featureName: string]: FeatureFlag
}

const initialState: FeatureFlagState = {
  isDtcEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNftEnabled: {
    env: []
  },
  isNewLoginEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNewExportsCSVEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isNewHQTransactEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isMultiLinePaymentEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isBankFeedEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isXeroCertificationEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isDraftTransactionsEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isQueueTransactionsEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isSpotBalanceEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isAnnotationEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isOffRampEnabled: {
    env: ['localhost', 'development', 'staging', 'production']
  },
  isBatchExecuteEnabled: {
    env: ['localhost', 'development', 'staging', 'production', 'demo']
  },
  isMultiChainSafeEnabled: {
    env: ['localhost', 'development', 'staging', 'production']
  },
  isCardsEnabled: {
    env: [ENVIRONMENT.localhost, ENVIRONMENT.development, ENVIRONMENT.staging],
    organizations: ['def2920b-76a7-4395-82dd-e4776e1a7836', 'c768993a-ddec-49c7-b1d3-d3813897e761']
  },
  rootfiService: {
    isEnabled: false
  }
}

export const featureFlagSlice = createSlice({
  name: 'feature-flags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      // @ts-ignore
      featureFlagApi.endpoints.getFeatureFlags.matchFulfilled,
      (state, { payload }) => {
        state.rootfiService.isEnabled = payload?.data?.isEnabled
      }
    )
  }
})

export default featureFlagSlice.reducer
