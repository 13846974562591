import { ROUTES } from './side-bar-links'
import { SubscriptionStatus } from '@/api/subscription-api'

interface IGetRoutesParams {
  isMobile: boolean
  organizationId: string
  planStatus: string
}

export const getRoutes = ({ isMobile, organizationId, planStatus }: IGetRoutesParams) => {
  const filteredRoutes = []
  const currentEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT

  if (isMobile) {
    // TODO: Temporarily fix

    for (const routeGroup of ROUTES) {
      const filteredRouteGroup = {
        title: routeGroup.title,
        routes: []
      }

      if (
        !routeGroup.whitelistEnvironment ||
        (routeGroup?.whitelistEnvironment && routeGroup.whitelistEnvironment.includes(currentEnvironment))
      ) {
        for (const route of routeGroup.routes) {
          if (route.whitelistedDevices) {
            if (route?.whitelistedDevices.includes('mobile')) {
              filteredRouteGroup.routes.push(route)
              if (filteredRouteGroup.routes.length > 0) {
                filteredRoutes.push(filteredRouteGroup)
              }
            }
          }
        }
      }
    }
  } else {
    for (const routeGroup of ROUTES) {
      const filteredRouteGroup = {
        title: routeGroup.title,
        routes: []
      }

      if (
        !routeGroup.whitelistEnvironment ||
        (routeGroup?.whitelistEnvironment && routeGroup.whitelistEnvironment.includes(currentEnvironment))
      ) {
        for (const route of routeGroup.routes) {
          if (
            !route.whitelistEnvironment ||
            route.whitelistEnvironment.includes(currentEnvironment) ||
            routeGroup?.whitelistOrganizations?.includes(organizationId)
          ) {
            // Disable links for expired gated experience
            if (route.path === '/pendingApproval') {
              route.title = 'Queue'
            }

            if (
              !route?.children?.length &&
              planStatus === SubscriptionStatus.EXPIRED &&
              !['/transfer', '/pendingApproval', '/contacts', '/orgsettings', '/transact/drafts'].includes(route.path)
            ) {
              filteredRouteGroup.routes.push({ ...route, active: false })
            } else if (route.children?.length > 0) {
              const { children } = route
              const routeData = {
                ...route,
                children: []
              }
              for (const child of children) {
                if (
                  planStatus === SubscriptionStatus.EXPIRED &&
                  ['/transact/drafts', '/transact/payment-history'].includes(child.path)
                ) {
                  routeData.children.push({ ...child, active: false })
                } else {
                  routeData.children.push(child)
                }
              }
              filteredRouteGroup.routes.push({ ...routeData })
            } else {
              filteredRouteGroup.routes.push(route)
            }
          }
        }
      }

      if (filteredRouteGroup.routes.length > 0) {
        filteredRoutes.push(filteredRouteGroup)
      }
    }
  }

  return filteredRoutes
}
